import React from 'react'
import { Styling } from './styled.js'
import ArrowDown from '../../../../images/arrow_down.inline.svg'
import ProductionCourseCard from '../../../../components/reusable/ProductionCourseCard'

export default function ProductionProgrammeRelatedCourses({similar, path, className}) {
    return (
        <Styling className={className}>
            <div className="title">Similar courses <ArrowDown /></div>
            <div className="similar">
            {
                 similar.nodes.map((course, index) => {
                   return <ProductionCourseCard key={`similar_course_${index}`} course={course} />
                 })
            }
            </div>
        </Styling>
    )
}
