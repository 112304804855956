import React from "react";
import { graphql } from "gatsby";
import ProductionProgrammeIntro from "../components/page-components/ProductionProgrammes/ProductionProgrammesIntro";
import ProductionProgrammeChapters from "../components/page-components/ProductionProgrammes/ProductionProgrammeChapters";
import ProductionProgrammeAbout from "../components/page-components/ProductionProgrammes/ProductionProgrammeAbout";
import ProductionProgrammeRelatedCourses from "../components/page-components/ProductionProgrammes/ProductionProgrammeRelatedCourses";
import Seo from "../components/global/seo";
import styled from "styled-components";

const ReorderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 1024px) {
    .aboutWrap {
      order: 1;
    }
    .chaptersWrap {
      order: 2;
    }
    .relatedWrap {
      order: 2;
    }
  }
`;

export default function ProductionProgramme(props) {
  const path = props.location.pathname;
  const data = props.data;
  const course = data.wpCourse;

  return (
    <ReorderWrapper>
      <Seo title={course.seo.title} description={course.seo.metaDesc} />
      <ProductionProgrammeIntro
        course={course}
        video={course.courseBuilder.trailerVideoUrl}
        title={course.title}
        path={path}
        index={0}
        snipcart_description={`Toolroom Academy Course: ${course.title}`}
        snipcart_meta={course.databaseId}
        snipcart_id={course.databaseId}
        snipcart_price={course.courseBuilder.price}
        externalUrl={course.courseBuilder.externalUrl}
        isSoldExternally={course.courseBuilder.isSoldExternally}
        snipcart_image={course?.courseBuilder?.courseThumbnailImage?.localFile?.publicURL}
        snipcart_guid={course?.customSnipcartGuid?.customSnipcartGuid}
        category="onDemand"
      />
      <ProductionProgrammeChapters course={course} path={path} className="chaptersWrap" />
      <ProductionProgrammeAbout
        course={course}
        path={path}
        className="aboutWrap"
        index={1}
        category="onDemand"
        externalUrl={course.courseBuilder.externalUrl}
        isSoldExternally={course.courseBuilder.isSoldExternally}
      />
      <ProductionProgrammeRelatedCourses
        similar={data.similar}
        className="relatedWrap"
        path={path}
      />
    </ReorderWrapper>
  );
}

export const query = graphql`
  query ($id: String!) {
    wpCourse(id: { eq: $id }) {
      id
      seo {
        title
        metaDesc
      }
      customSnipcartGuid {
        customSnipcartGuid
      }
      databaseId
      title
      courseBuilder {
        instructors {
          instructor {
            ... on WpInstructor {
              id
              title
            }
          }
        }
        courseThumbnailImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 1500, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
        curriculum {
          chapter {
            chapterTitle
            chapterDescription
            chapterThumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500, placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
            }
            lesson {
              lessonVideoDuration
            }
          }
        }
        price
        trailerVideoUrl
        detailedDescription
        externalUrl
        isSoldExternally
      }
    }
    similar: allWpCourse(limit: 3, filter: { id: { ne: $id } }) {
      nodes {
        id
        title
        slug
        courseBuilder {
          instructors {
            instructor {
              ... on WpInstructor {
                id
                title
              }
            }
          }
          courseThumbnailImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1500, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
          curriculum {
            chapter {
              chapterTitle
              chapterDescription
              chapterThumbnail {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1500, placeholder: BLURRED, formats: [AUTO, WEBP])
                  }
                }
              }
              lesson {
                lessonVideoDuration
              }
            }
          }
          price
          trailerVideoUrl
          detailedDescription
        }
      }
    }
  }
`;
