
import styled from 'styled-components'

const Styling = styled.div`
position: relative;
width: 100%;
color:var(--black);
background:var(--white);
display: flex;
flex-wrap: wrap;
padding:0 2rem 4rem 2rem;

    @media only screen and (min-width: 350px) {
    padding:4rem 2rem;
    }
    
    @media only screen and (min-width: 768px) {
    padding:4rem;
    }

    @media only screen and (min-width: 1024px) {
    padding:8rem;
    }

    .title{
    text-transform:uppercase;
    ${({theme}) => theme.tomato('regular')}  
    position:relative;
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
    width: 100%;
    letter-spacing: -0.2rem;
    margin-bottom: 4rem;
    font-size:3rem;
    line-height:3rem;
        
        @media only screen and (min-width: 350px) {
        font-size:3.5rem;
        line-height:3.5rem;
        letter-spacing: -0.2rem;
        }

        @media only screen and (min-width: 420px) {
        font-size:4rem;
        line-height:4rem;
        letter-spacing: -0.3rem;
        }
        
        @media only screen and (min-width: 610px) {
        font-size:5rem;
        line-height:5rem;
        letter-spacing: -0.4rem;
        }
        
        @media only screen and (min-width: 800px) {
        font-size:clamp(4rem, 4vw, 6rem); 
        letter-spacing: -0.04em;
        }
        
        @media only screen and (min-width: 1024px) {
        letter-spacing: -0.2rem;
        font-size:clamp(4rem, 1.6vw, 6rem);
        line-height: clamp(4rem/1.2, 1.6vw/1.2, 6rem/1.2);
        }

        svg{
        width: 0.7em;
        height: 0.7em;
        margin-left:1rem;
        position: relative;

            path{
            fill:black;
            }
        }

    }

    .similar{
    width: 100%;
    width:100%;
    display:grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
    
        @media only screen and (min-width: 768px) {
        padding:0 0 8rem 0;
        grid-template-columns: repeat(2, 1fr);
        }

        @media only screen and (min-width: 960px) {
        grid-template-columns: repeat(3, 1fr);
        }

    }

`

export { Styling }